import React from 'react'
import {
  Link, graphql,
} from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import {
  Page, Main, Paragraph, Content, Oops, OopsText, Underline,
} from '../../components/StyledComponents'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageHero from '../../components/Blocks/PageHero'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-how-it-works-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-how-it-works.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({
  data,
}) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]
  return (
    <Layout>
      <SEO
        title='500'
        description='Internal Server Error.'
      />
      <Page id='site-page'>
        <Header />
        <Main>
          <PageHero
            background={sources}
            title='500'
          />
          <Content short center>
            <Oops>Oops!</Oops>
            <OopsText>There was a server issue.</OopsText>
            <Paragraph>
              If you continue to have issues, please <Underline><Link to='/about/#contact'>Contact Us</Link></Underline>.
            </Paragraph>
            <Paragraph>
              Otherwise, return to the <Underline><Link to='/'>Home Page</Link></Underline>.
            </Paragraph>
          </Content>
        </Main>
      </Page>
      <Footer />
    </Layout>
  )
}
